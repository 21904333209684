<template>
  <v-main class="mt-16">
    <!-- <section>
      <div class="container py-15 mt-2">
        <div class="row">
          <div class="col col-12">
            <h2 style="font-size: 25px">Top Restaurants</h2>
          </div>
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-12"
            v-for="res in Restaurants"
            :key="res.id"
          >
            <div
              class="
                pb-2
                br-10
                o-hidden
                v-card v-sheet v-sheet--outlined
                theme--light
                elevation-0
              "
            >
              <div class="position-relative">
                <div v-if="editImage == false" style="width: 100%">
                  <v-img
                    :src="
                      res.images != null &&
                      res.images != undefined &&
                      res.images[res.images.length - 1] != null &&
                      res.images[res.images.length - 1] != undefined
                        ? res.images[0].fileUrl
                        : '/placeholder.png'
                    "
                  ></v-img>
                </div>
                <div v-if="editImage == true">
                  <ImageAdd
                    style="width: 100%"
                    :objectPath="'/restaurant/' + res.id"
                  ></ImageAdd>
                </div>

                <div>
                  <span
                    class="
                      p-absolute
                      top-0
                      rounded-r-xl rounded-l-0
                      mt-3
                      v-chip v-chip--label
                      theme--light
                      v-size--default
                      primary
                    "
                    flat=""
                    ><span class="v-chip__content">Flat 30% Off</span>
                  </span>
                  <v-btn
                    @click="_inv"
                    icon
                    style="right: 0"
                    class="p-absolute top-0"
                    dark
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>

              <div
                class="pa-4 d-flex justify-space-between align-center"
                @click="_goDetail(res.id)"
              >
                <span
                  style=""
                  class="
                    d-inline-block
                    text-truncate
                    me-3
                    f-600
                    text-18
                    card-title
                  "
                  >{{ res.name }}</span
                >
                <div class="d-flex align-center card-rating">
                  <i
                    aria-hidden="true"
                    class="
                      v-icon
                      notranslate
                      me-1
                      mt-1
                      mdi mdi-star
                      theme--light
                      yellow--text
                      text--darken-2
                    "
                    style="font-size: 16px"
                  ></i>
                  <div style="line-height: 0">
                    <span class="font-weight-bold text-14">{{
                      res.rating
                    }}</span>
                    <span class="grey--text text--darken-1 text-14">/5</span>
                  </div>
                </div>
              </div>
              <p class="px-4 grey--text text--darken-1">
                <span class="primary--text f-600 me-1">${{ res.price }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section>
      <div class="container py-15">
        <div class="row">
          <div class="col col-12"><h2>Traditional Mongolian Cuisine</h2></div>
          <div
            class="col-sm-6 col-md-4 col-lg-2 col-12"
            v-for="(food, findex) in foods"
            :key="findex"
          >
            <div class="text-center">
              <a :href="food.src" class="text-decoration-none"
                ><div
                  class="v-avatar mb-4"
                  style="height: 150px; min-width: 150px; width: 150px"
                >
                  <img :src="food.src" alt="" />
                </div>
                <h4 class="grey--text text--darken-2">{{ food.name }}</h4></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container py-15">
        <div class="row">
          <div class="col col-12">
            <div class="d-flex justify-space-between align-end flex-wrap">
              <div>
                <h2>Top Restaurants in Mongolia</h2>
                <h6 class="grey--text grey--lighten-3 font-weight-regular">
                  Explore curated lists of top restaurants, cafes, pubs, and
                  bars in Ulaanbaatar, based on trends
                </h6>
              </div>
              <!-- <div>
                <a
                  href="/"
                  aria-current="page"
                  class="
                    text-decoration-none
                    nuxt-link-exact-active nuxt-link-active
                  "
                  >View all</a
                >
              </div> -->
            </div>
          </div>
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-12"
            v-for="(icard, iindex) in restaurants"
            :key="iindex"
          >
            <a href="/resturant/SearchResturant" class="text-decoration-none"
              ><div class="br-10 v-card v-sheet theme--light">
                <v-img :src="icard.src">
                  <div
                    class="p-absolute bottom-0 pa-5 mb-0 pb-0"
                    style="position: absolute; bottom: 0"
                  >
                    <span
                      class="white--black shadowed-text pa-0 ma-0"
                      style="display: block"
                      >{{ icard.name }}</span
                    >
                    <span class="white--text mb-0"> Restaurant </span>
                    <div class="d-flex">
                      <h3 class="white--text mb-0">
                        {{ icard.description }}
                      </h3>
                      <i
                        aria-hidden="true"
                        class="
                          v-icon
                          notranslate
                          mdi mdi-chevron-right
                          theme--light
                          black--text
                        "
                        style="font-size: 16px"
                      ></i>
                    </div></div
                ></v-img></div
            ></a>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container py-15">
        <div class="row">
          <div class="col col-12">
            <div class="d-flex justify-space-between align-end flex-wrap">
              <div>
                <h2>How to make Mongolian Cuisine</h2>
                <h6 class="grey--text grey--lighten-3 font-weight-regular">
                  Explore curated lists of top restaurants, cafes, pubs, and
                  bars in Ulaanbaatar, based on trends
                </h6>
              </div>
              <!-- <div>
                <a
                  href="/"
                  aria-current="page"
                  class="
                    text-decoration-none
                    nuxt-link-exact-active nuxt-link-active
                  "
                  >View all</a
                >
              </div> -->
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12" md="3" lg="3" v-for="(vurl, iindex) in youtubes" :key="iindex">
            <iframe
              width="280"
              height="180"
              :src="vurl"
              title="YouTube video player"
              style="border-radius:4px"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-col>
        </v-row>
      </div>
    </section>
  </v-main>
</template>
<script>
import { db } from "@/firebaseConfig.js";
import ImageAdd from "./ImageAdd.vue";

export default {
  components: {
    ImageAdd,
  },
  data() {
    return {
      foods: [
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/9.1c1a4aa.png",
          name: "Xуушуур",
          description: "asdfasdfasf",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/9.1c1a4aa.png",
          name: "Банштай цай",
          description: "asdfasdfasf",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/9.1c1a4aa.png",
          name: "Бууз",
          description: "asdfasdfasf",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/9.1c1a4aa.png",
          name: "Борцтой шөл",
          description: "asdfasdfasf",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/9.1c1a4aa.png",
          name: "Чанасан толгой",
          description: "asdfasdfasf",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/9.1c1a4aa.png",
          name: "Xорxог",
          description: "asdfasdfasf",
        },
      ],
      restaurants: [
        {
          src: "./homes/restaurants/nomads.jpg",
          name: "Modern Nomads",
          description: "*****",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/6.729f74b.png",
          name: "Mongolians",
          description: "*****",
        },
        {
          src: "./homes/restaurants/altangadas2.jpg",
          name: "Altangadas",
          description: "*****",
        },
        {
          src: "https://foodhub-nuxt.vercel.app/_nuxt/img/6.729f74b.png",
          name: "Taij",
          description: "*****",
        },
      ],
      youtubes: [
        "https://www.youtube.com/embed/2csMRp-lbUw",
        "https://www.youtube.com/embed/wI_Mc_OUD-M",
        "https://www.youtube.com/embed/xTyNpYDu_Xs",
        "https://www.youtube.com/embed/simAX-lD4qo",
      ],
      Restaurants: Array.from([], function mapFn(element) {
        return { title: element, subtitle: element, headline: element };
      }),
      editImage: false,
      name: null,
      price: null,
    };
  },
  methods: {
    _goDetail(id) {
      this.$router.push({
        name: "RestaurantDetail",
        params: { restaurantId: id },
      });
    },
    _inv() {
      this.editImage = !this.editImage;
    },
    async addItem() {
      if (this.name && this.price) {
        await db.collection("restaurant/").add({
          name: this.name,
          price: this.price,
          createdAt: new Date(),
          rating: null,
        });
        this.name = null;
        this.price = null;
      }
    },
  },
  firestore: {
    Restaurants: db.collection("restaurant").orderBy("createdAt", "asc"),
  },
};
</script>
<style>
.p-absolute {
  position: absolute !important;
}
.top-0 {
  top: 0 !important;
}
.text-18 {
  font-size: 20px !important;
}

.f-600 {
  font-weight: 400;
}
.text-14,
h6 {
  font-size: 14px;
}
.shadowed-text {
  font-size: 28px;
  color: white !important;
}
</style>