<template>
  <div v-if="object" style="width: 100%; height: 100%">
    <div
      class="text-center ma-0"
      v-if="object.images != null && object.images.length > 0"
      style="width: 100%; height: 100%"
    >
      <div
        v-for="(file, index) in object.images"
        v-bind:key="file.fileUrl"
        class="py-0"
        style="position: relative; width: 100%; height: 100%"
      >
        <a href="#" style="width: 100%; height: 100%">
          <v-img
            :src="file.fileUrl"
            @click="deleteFile(file, index)"
            height="100%"
            width="100%"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </a>
      </div>
    </div>

    <v-col cols="6" class="ma-2 py-0 mr-4" v-else
      ><v-alert color="red" class="mb-0">Зураг ороогүй байна.</v-alert></v-col
    >

    <v-col cols="5" class="pa-0"
      ><input
        type="file"
        ref="file"
        @change="uploadFile1"
        class="inputfile"
        :id="'file' + object.id"
      />
      <v-btn depressed x-large color="transparent" class="pa-0">
        <label :for="'file' + object.id" style="cursor: pointer">
          <v-icon>mdi-paperclip</v-icon>
          Зургаа сонгох</label
        >
        <div v-if="uploading">{{ uploadPercent.toFixed() }}%</div>
      </v-btn>
    </v-col>
  </div>
</template>
<style>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
<script>
const fb = require("@/firebaseConfig.js");

export default {
  name: "ImageAdd",
  data() {
    return {
      object: null,
      uploading: false,
      uploadPercent: null,
    };
  },
  created() {
    if (this.objectPath != null && this.objectPath != "") {
      this._getData();
    }
  },
  methods: {
    async _getData() {
      var tourData = await fb.db.doc(this.objectPath).get();
      let object = tourData.data();
      object.id = tourData.id;
      this.object = object;
    },
    uploadFile1() {
      var _this = this;
      var files = this.$refs["file"].files;

      if (files != null && files.length > 0) {
        var file = files[0];
        var now = new Date();
        var fileName = +now.getTime() + "_" + file.name;
        var uploadTask = fb.storageRef.child(fileName).put(file);
        _this.uploading = true;
        _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                let fileObject = {
                  fileName: fileName,
                  fileUrl: downloadURL,

                  uploadedAt: now,
                };
                fb.db.doc(_this.objectPath).update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                });

                if (_this.object != null) {
                  if (_this.object.images == null) _this.object.images = [];
                  _this.object.images.push(fileObject);
                }
                (_this.uploading = false), (_this.uploadPercent = null);

                _this.$refs["file"].value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs["file"].type = "";
                  _this.$refs["file"].type = "file";
                }
              });
          }
        );
      }
    },
    deleteFile(file, index) {
      var result = confirm("Та " + file.fileName + " файлыг устгах уу?");
      if (result) {
        var fileRef = fb.storageRef.child(file.fileName);
        fileRef
          .delete()
          .then(() => {
            this.object.images.splice(index, 1);
            fb.db.doc(this.objectPath).update({
              images: this.object.images,
            });
          })
          .catch(function () {});
      }
    },
  },
  props: {
    objectPath: {
      required: true,
    },
  },
};
</script>
